<template>
  <div>
    <div class="cart-container">
      <v-card height="480">
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <!-- https://api.whatsapp.com/send?phone=+905523447073&text=Hello%from%20i%204talents -->
        <router-link
          :to="
            coaching
              ? '#'
              : !sold
              ? `/details/${product.id}?type=${+type}`
              : sold && +type == 1
              ? `/course-content/${product.course_id.id}`
              : sold && +type == 2 && product.result_option_id
              ? `/result/${product.id}`
              : sold && +type == 2 && !product.result_option_id
              ? `/quiz/${product.course_id.id}/${product.id}`
              : '/'
          "
          style="textDecoration: none;color: inherit"
        >
          <div>
            <div style="position:relative">
              <div class="overlay" />
              <v-img
                height="250"
                :src="
                  coaching
                    ? product.image.data.full_url
                    : product.course_id
                    ? product.course_id.featured_image.data.full_url
                    : !sold
                    ? product.featured_image.data.full_url
                    : product.course_id.featured_image.data.full_url
                "
              />
            </div>

            <v-card-title class="body-1 font-weight-bold singleLine mt-4">{{
              coaching
                ? product[lang + 'countery']
                : product.course_id
                ? product.course_id[lang + 'title']
                : !sold
                ? product[lang + 'title']
                : product.course_id[lang + 'title']
            }}</v-card-title>
            <v-card-subtitle v-if="sold">
              <span>{{ $t('boughtOn') }} </span
              >{{ product.created_on | formateDate }}</v-card-subtitle
            >

            <v-card-text>
              <div class="body-2 singleLine">
                {{
                  coaching
                    ? product[lang + 'description']
                    : !sold
                    ? product[lang + 'short_discription']
                    : product.course_id[lang + 'short_discription']
                      | descLenghth
                }}
                <!-- | descLenghth.slice(0, 50) -->
              </div>

              <div class="d-flex flex-row align-center mt-4" v-if="!sold">
                <div
                  class="before-discount"
                  style="fontSize:14px;"
                  v-if="product.before_discount"
                >
                  <span>USD</span>
                  {{ calcPrice('before_discount') }}
                  <!-- {{ coaching ? product.price : calcPrice('before_discount') }} -->
                </div>

                <div
                  class="text--primary mx-5"
                  style="fontSize:18px; fontWeight: bold"
                >
                  <span
                    v-if="
                      !isNaN(product.price) || !isNaN(product.after_discount)
                    "
                    >USD</span
                  >
                  {{ coaching ? product.price : calcPrice('after_discount') }}
                </div>
              </div>
            </v-card-text>
          </div>
        </router-link>
        <v-card-actions v-if="!sold">
          <v-btn
            v-if="
              this.userGetter.currentUser &&
                this.userGetter.currentUser.role == '1' &&
                type !== 3
            "
            icon
            fab
            color="primary"
            link
            :to="
              type == 2 ? `quiz/${product.id}` : `course-content/${product.id}`
            "
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-spacer />

          <template v-if="!coaching">
            <v-btn
              icon
              fab
              :color="itemInPocket('favorite') ? 'secondary' : 'primary'"
              @click="cartHandler('favorite')"
            >
              <v-icon>
                {{
                  itemInPocket('favorite') ? 'mdi-heart' : 'mdi-heart-outline'
                }}
              </v-icon>
            </v-btn>
            <!-- <v-btn
              icon
              fab
              :color="itemInPocket('cart') ? 'secondary' : 'primary'"
              @click="cartHandler('cart')"
            >
              <v-icon>{{
                itemInPocket('cart') ? 'mdi-cart-remove' : 'mdi-cart-plus'
              }}</v-icon>
            </v-btn> -->
          </template>
          <v-btn
            v-if="
              (type == 3 || coaching) && (product.whatsapp || product.phone)
            "
            :href="
              `https://web.whatsapp.com/send?phone=${product.whatsapp ||
                product.phone}`
            "
            icon
            fab
            color="primary"
            target="_blank"
          >
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <Auth
      v-if="showAuthForm"
      :dialog="showAuthForm"
      @closeDialog="showAuthForm = false"
    />
    <!-- snackbar -->
    <v-snackbar
      v-model="cartGetter.secondaryCart"
      absolute
      top
      :multi-line="true"
      :color="snackbarColor"
      timeout="-1"
    >
      {{ cartGetter.secondaryCart }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from 'moment';
import Auth from '@/components/Auth';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    product: {
      type: Object,
    },
    sold: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
    },
    coaching: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Auth,
  },
  data() {
    return {
      loading: false,
      selection: 1,
      snackbarColor: 'secondary',
      showAuthForm: false,
      lang: this.$root.lang == 'en' ? 'en' : '',
    };
  },
  methods: {
    ...mapActions(['addToCartAction', 'removeCartItemAction']),

    cartHandler(type) {
      if (this.userGetter.currentUser) {
        const productExists = this.cartGetter[type]?.find(
          (item) =>
            item.course_id?.id == this.product.course_id?.id ||
            item.course_id?.id == this.product.id
        );
        productExists
          ? this.removeCartItemAction({ productId: productExists.id, type })
          : this.addToCartAction({ productId: this.product.id, type });
      } else {
        this.showAuthForm = true;
      }
    },

    itemInPocket(pocket) {
      if (this.userGetter.currentUser) {
        return this.cartGetter[pocket].find(
          (item) =>
            item.course_id?.id == this.product.course_id?.id ||
            item.course_id?.id == this.product.id
        );
      } else {
        console.log('no user');
      }
    },

    calcPrice(period) {
      return this.product[period];
    },

    whatsappContact() {
      // this.$vuetify.gotTo(
      //   'https://api.whatsapp.com/send?phone=+905523447073&text=Hello%from%20i%204talents'
      // );
      window.location.href =
        'https://web.whatsapp.com/send?phone=+905523447073&text=Hello%from%20i%204talents';
    },
  },
  computed: {
    ...mapGetters(['cartGetter', 'userGetter']),
  },
  filters: {
    formateDate(date) {
      if (!date) return;
      // else return date;
      else return moment(date).format('DD/MM/YYYY');
    },
    descLenghth(str) {
      if (!str) return;
      else return str.slice(0, 100);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.cart-container {
  cursor: pointer;
  position: relative;
}

.cart-container .product-desc {
  position: absolute;
  top: calc((100% - 250px) / 2.6);
  left: -260px;
  width: 250px;
  /* min-height: 300px; */
  z-index: 500;
  background: #e1e1e1;
  display: none;
  transition: all 0.3s ease-in-out;
}

.cart-container:hover .product-desc {
  display: flex;
}

.cart-container .product-desc::after {
  content: '';
  background: transparent;
  border-left: 20px solid #e1e1e1;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  position: absolute;
  top: calc((100% - 20px) / 2.2);
  right: -10px;
}

.overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all 0.3s ease-in-out;
  z-index: 10;
}

.cart-container:hover .overlay {
  width: 100%;
  height: 100%;
}

.singleLine {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style>
.before-discount {
  text-decoration: line-through;
  color: var(--v-error-base);
}
</style>
