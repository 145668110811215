<template>
  <BasePage :loading="loading" :overlayValue="cartGetter.loadingCart">
    <Container>
      <!-- <div
        style="width: 100%;height:80vh"
        class="d-flex justify-center align-center"
        v-if="loading"
      >
        <v-progress-circular
          color="primary"
          indeterminate
          size="30"
        ></v-progress-circular>
      </div> -->
      <v-row align="center" v-if="courses && !loading">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="course in courses"
          :key="course.id"
        >
          <ProductItem :product="course" :type="+type" />
        </v-col>
      </v-row>
    </Container>
  </BasePage>
</template>

<script>
import ApiService from '@/services/api.service';
import ProductItem from '@/components/ProductItem';
import { mapGetters } from 'vuex';
export default {
  components: {
    ProductItem,
  },
  data() {
    return {
      loading: false,
      courses: [],
      type: this.$route.query.type,
    };
  },
  methods: {
    async getCourses() {
      try {
        this.loading = true;
        const { data } = await ApiService.get(
          `items/courses?fields=*,featured_image.data.full_url,currency_id.title&filter[type_id.id]=${this.type}`
        );

        this.courses = data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters(['cartGetter']),
  },
  mounted() {
    this.getCourses();
  },
};
</script>

<style></style>
